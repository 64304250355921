import { LogoutModel } from "../../../models/oneDrive/auth/logout.model";
import { BaseDeleteService } from "../../base/base.delete.service"



export class OneDriveLogoutService extends BaseDeleteService<LogoutModel>{
    
    constructor(controllerName:string="logout",endpoint:string="oneDrive"){
        super(controllerName,endpoint)
    }
}