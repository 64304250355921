
import { GetBoxAuthorizationUrlResponseModel } from "../../../models/auth/box/box.auth.url.model";
import { BaseGetService } from "../../base/base.get.service";





export class GetBoxAuthorizationUrlService extends BaseGetService<GetBoxAuthorizationUrlResponseModel>{
    constructor(controllerName:string="auth",endpointName:string="boxAuthUrl") {
        super(controllerName,endpointName);

    }
}