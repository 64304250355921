import axios from 'axios';
//export const baseUrl="https://sync3ds.com/api/v1/"
//export const baseUrl = 'http://localhost:5057/api/v1/';
//export const baseUrl= 'http://localhost:35542/api/v1/'
export const baseUrl="https://presentation20231030160232.azurewebsites.net/api/v1/"
const http = (
	controllerName: string,
	endpoint: string,
	contentType: string = 'application/json;charset=UTF-8'
) => {
	const url: string = `${baseUrl}${controllerName}/${endpoint}`;
	const instance = axios.create({
		baseURL: url,
		withCredentials: true,
		headers: {
			'Content-Type': contentType, 
			'Access-Control-Allow-Origin':'react'
		}
		
	});
	return instance;
};
export default http;
