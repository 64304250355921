import {NavDropdown, NavItem } from "react-bootstrap"
import { Link } from "react-router-dom"
import { GoogleDriveLogoutService } from "../../services/googleDrive/auth/logout.googleDrive.service"
import { LoginResponse } from "../../models/auth/login.model"
import { LogoutService } from "../../services/auth/logout.service"
import { useEffect } from "react"




const GoogleDriveNav=()=>{

    const clickedToGoogleDrive=sessionStorage.getItem("clickGoogleDrive")

    useEffect(()=>{
        if(clickedToGoogleDrive!==null && clickedToGoogleDrive==="true"){
            sessionStorage.removeItem(clickedToGoogleDrive)
        }
    },[clickedToGoogleDrive])

    return (
            <NavDropdown as={NavItem} title="GoogleDrive" className="dropend text-center" drop="end" style={{
                fontSize:'1.1rem'
            }}>
                <NavDropdown.Item><Link className="dropdownItem" to={"/googleDrive/upload"}>Upload</Link></NavDropdown.Item>
                <NavDropdown.Item><Link className="dropdownItem" to={"/googleDrive/files"}>Translated Files</Link></NavDropdown.Item>
                <NavDropdown.Item className="dropdownItem" onClick={async()=>{
                    const logoutService=new GoogleDriveLogoutService()
                    const b=sessionStorage.getItem("b") as string
                    const res=await logoutService.DeleteByStringParamByAsync(b)
                    if(res.isSuccess){
                        const googleDriveInternalAuth=sessionStorage.getItem("login") as string
                        const jsonGoogleDriveInternalAuth=JSON.parse(googleDriveInternalAuth) as LoginResponse
                        if(googleDriveInternalAuth && jsonGoogleDriveInternalAuth.google_auth && jsonGoogleDriveInternalAuth.isAuthenticated){
                            const secondaryLogoutService=new LogoutService()
                            const secondaryRes=await secondaryLogoutService.GetAsync()
                            if(secondaryRes.isSuccess){
                                sessionStorage.removeItem('login');
                                sessionStorage.removeItem('b')
                            }          
                        }
                        sessionStorage.removeItem("google-auth")
                        //sessionStorage.removeItem("clickGoogleDrive")
                        window.location.replace("/")
                      
                    }
                }}>Logout</NavDropdown.Item>
        
        </NavDropdown>
    )
}


export default GoogleDriveNav