import {NavItem,NavDropdown} from "react-bootstrap"
import { Link } from "react-router-dom"
import { BoxLogoutService } from "../../services/box/auth/logout.box.service"
import { useEffect } from "react"





const BoxNav=()=>{
    const clickedToBox=sessionStorage.getItem("clickBox")

    useEffect(()=>{
        if(clickedToBox!==null && clickedToBox==="true"){
            sessionStorage.removeItem(clickedToBox)
        }
    },[clickedToBox])

    return (
      
            <NavDropdown as={NavItem} title="Box" className="dropend text-center" drop="end" style={{
                fontSize:'1.1rem'
            }}> 
                <NavDropdown.Item><Link className="NavDropdownItem" to={"/box/upload"}>Upload</Link></NavDropdown.Item>
                <NavDropdown.Item><Link className="NavDropdownItem" to={"/box/files"}>Translated Files</Link></NavDropdown.Item>
                <NavDropdown.Item className="NavDropdownItem" onClick={async()=>{
                    const logoutService=new BoxLogoutService()
                    const b=sessionStorage.getItem("b") as string
                    const res=await logoutService.DeleteByStringParamByAsync(b)
                    console.log(res)
                    if(res.isSuccess){   
                        sessionStorage.removeItem("box-auth")
                        //sessionStorage.removeItem("clickBox")
                        window.location.replace("/")
                        
                    }
                }}>Logout</NavDropdown.Item>
        </NavDropdown>
        
        
    )
}


export default BoxNav