import {NavDropdown, NavItem } from "react-bootstrap"
import { Link } from "react-router-dom"
import { useEffect } from "react"
import { AWSLogoutService } from "../../services/aws/auth/logout.aws.service"





const BoxNav=()=>{
    const clickedToAWS=sessionStorage.getItem("clickAWS")

    useEffect(()=>{
        if(clickedToAWS!==null && clickedToAWS==="true"){
            sessionStorage.removeItem(clickedToAWS)
        }
    },[clickedToAWS])

    return (
            <NavDropdown as={NavItem} title="AWS" className="dropend text-center" drop="end" style={{
                fontSize:'1.1rem'
            }}>
                <NavDropdown.Item><Link className="dropdownItem" to={"/aws/upload"}>Upload</Link></NavDropdown.Item>
                <NavDropdown.Item><Link className="dropdownItem" to={"/aws/files"}>Translated Files</Link></NavDropdown.Item>
                <NavDropdown.Item className="dropdownItem" onClick={async()=>{
                    const logoutService=new AWSLogoutService()
                    const b=sessionStorage.getItem("b") as string
                    const res=await logoutService.DeleteByStringParamByAsync(b)
                    if(res.isSuccess){   
                        sessionStorage.removeItem("aws-auth")
                       // sessionStorage.removeItem("clickAWS")
                        window.location.replace("/")
                        
                    }
                }}>Logout</NavDropdown.Item>
        </NavDropdown>      
    )
}


export default BoxNav