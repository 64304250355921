import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlushed } from '@fortawesome/free-regular-svg-icons';
import CardItem from '../../components/CardItem';
import ConfirmModal from '../../components/Modals/ConfirmDeleteModal';
import MultipleUploadModal from '../../components/MultipleUploadModal/MultipleUploadModal';
import TextAreaModal from '../../components/Modals/TextAreaModal';
import Unauthorized from '../../components/Unauthorized';
import { GetSubUploadedObjectResponseModel, GetUploadedObjectResponseModel } from '../../models/object/uploadedObject/get.uploadedObject.response.model';
import { GetUploadedObjectHtmlContentService } from '../../services/uploadedObject/get.uploadedObject.html.content.service';
import { GetUploadedObjectModelViewService } from '../../services/uploadedObject/get.uploadedObject.model.view.service';
import { RootState, useAppDispatch, useAppSelector } from '../../store';
import { getUploadedObjects } from '../../store/features/object/get.list.uploadedObject.slice';
import IAM from '../../assets/IAM.png';
import IPT from '../../assets/IPT.png';
import ZIP from '../../assets/zip.svg';
import { baseUrl } from '../../config/axios.config';
import CircleLoading from '../../components/Loading/CircleLoading';
import '../../styles/objectList/objectList.css';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { LoginResponse } from '../../models/auth/login.model';
import { deleteUploadedObject } from '../../store/features/object/delete.object.slice';
import { UploadedFile } from '../../models/object/uploadedObject/put.uploadedObject.request.model';
import JSZip from 'jszip';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemText, Typography } from '@mui/material';


export interface SelectableZipFiles {
  mainZipFile: File
  subFiles: SelectableSubFiles[]

}

export interface SelectableSubFiles {
  fileName: string
  fileSize: number,
  file: File
}

const ObjectList: React.FC = () => {
  const [uploadModal, setUploadModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [htmlContent, setHtmlContent] = useState('');
  const [textAreaModal, setTextAreaModal] = useState(false);
  const [fileLimit, setFileLimit] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<UploadedFile[]>([]);
  const [selectedSearchValue, setSelectedSearchValue] = useState('');
  const [selectedDeleteValue, setSelectedDeleteValue] = useState('');
  const [zipFile, setZipFile] = useState<SelectableZipFiles | undefined>(undefined)
  const [openZipOption, setOpenZipOption] = useState(false);
  const [extractZipFileStatus, setExtractZipFileStatus] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const objectListSelector = useAppSelector((s: RootState) => s.getUploadedObjectList);
  const objectList = objectListSelector.data;
  const selectedObject = useAppSelector((s: RootState) => s.getUploadedObject);
  const selectedObjectByBucket = useAppSelector((s: RootState) => s.getUploadedObjectByBucketAndName);
  const deleteSelectedObject = useAppSelector((s: RootState) => s.deleteObjectSlice)

  const bucketKey = sessionStorage.getItem('b') as string;
  const loginInfo = sessionStorage.getItem('login') as string;
  const jsonLoginInfo = JSON.parse(loginInfo) as LoginResponse

  const [openSupportedFormats, setOpenSupportedFormats] = useState(false);
  const supportedFormats = [
    "3DM", "3DS", "A", "ASM", "AXM", "BRD", "CATPART", "CATPRODUCT", "CGR", "COLLABORATION", "DAE", "DDX", "DDZ", "DGK",
    "DGN", "DLV3", "DMT", "DWF", "DWFX", "DWG*", "DWT", "DXF", "EMODEL", "EXP", "F3D", "FBRD", "FBX", "FSCH", "G", "GBXML",
    "GLB", "GLTF", "IAM", "IDW", "IFC", "IGE", "IGES", "IGS", "IPT", "IWM", "JT", "MAX", "MODEL", "MPF", "MSR", "NEU",
    "NEW", "NWC", "NWD", "OBJ", "OSB", "PAR", "PMLPRJ", "PMLPRJZ", "PRT", "PSM", "PSMODEL", "RVM", "RVT**", "SAB", "SAT",
    "SCH", "SESSION", "SKP", "SLDASM", "SLDPRT", "SMB", "SMT", "STE", "STEP", "STL", "STLA", "STLB", "STP", "STPZ", "VPB",
    "VUE", "WIRE", "X_B", "X_T", "XAS", "XPR"
  ];

  const MAX_COUNT = 15;
  const UploadFilesSwal = withReactContent(Swal);

  const processZipFile = async (file: File) => {
    const zip = new JSZip();
    const zipContent = await zip.loadAsync(file);

    let subFiles: SelectableSubFiles[] = [];

    setExtractZipFileStatus(true)

    for (const relativePath of Object.keys(zipContent.files)) {
      const f = zipContent.files[relativePath];
      if (!f.dir) {
        const fileName = f.name.split('/').pop() as string;
        const fileSizeInKB = ((f as any)._data.uncompressedSize / 1024).toFixed(2);

        const fileBlob = await f.async("blob");
        const file = new File([fileBlob], fileName, { type: fileBlob.type });

        if (file.name.endsWith('.iam')) {
          console.log(`File: ${fileName}`);

          if (file.name.includes("\\")) {
            var splittedFileName = file.name.split("\\");
            var actualFileName = splittedFileName[splittedFileName.length - 1]
            subFiles.push({
              file,
              fileName: actualFileName,  // Sadece dosya adını alıyoruz
              fileSize: Number(fileSizeInKB)
            });
          } else {
            subFiles.push({
              file,
              fileName,
              fileSize: Number(fileSizeInKB)
            });
          }

        }
      }
    }



    setZipFile(() => {
      return {
        mainZipFile: file,
        subFiles
      };
    });
    setExtractZipFileStatus(false)
  };



  const handleUploadFiles = (chosenFiles: File[]) => {
    const uploaded = [...selectedFiles];
    let limitedExceeded = false;

    chosenFiles.map((file, i) => {
      if (uploaded.findIndex((f) => f.file.name === file.name) === -1) {

        if (file.name.endsWith('.zip')) {
          setOpenZipOption(true)
        }
        uploaded.push({
          file: file,
          rootFileName: ""
        });
      }

      if (uploaded.length === MAX_COUNT) setFileLimit(true);

      if (uploaded.length > MAX_COUNT) {
        UploadFilesSwal.fire({
          title: <h3>Maximum upload file limit warning</h3>,
          didOpen: () => {
            UploadFilesSwal.showLoading();
          },
        }).then(() => {
          UploadFilesSwal.fire(
            <p>{`You can only add a maximum of ${MAX_COUNT} files`}</p>
          );
        });
        setFileLimit(false);
        limitedExceeded = true;
        return true;
      }
    });

    if (!limitedExceeded) {
      console.log(uploaded);
      setSelectedFiles(uploaded);
      setUploadModal(true);
    }
  };

  const handleFileEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  const handleCloseModal = () => {
    setUploadModal(false);
    setSelectedFiles([]);
  };

  const handleFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = Array.prototype.slice.call(event.dataTransfer.files);
    handleUploadFiles(files);
  };

  useEffect(() => {
    dispatch(
      getUploadedObjects({
        bucketKey,
      })
    );
  }, [dispatch, bucketKey]);

  const openObjectDetail = (object: GetSubUploadedObjectResponseModel) => {
    navigate('/object', {
      state: {
        object,
      },
    });
  };

  return loginInfo && jsonLoginInfo.isAuthenticated ? (
    <>
      {objectListSelector.loading ? (
        <div className='loading-bucket'>
          <CircleLoading text='Loading Models' />
        </div>
      ) : (
        <div className='object-list p-0 h-full bg-[#f6f7f9]'>
          <div className='w-full px-12 py-2 bg-white border-b-2 border-gray-400'>
            <button
              className='object-list-upload-btn '
              onClick={() => {
                setUploadModal(true);
              }}
            >
              Upload
            </button>
            {/* <Typography
              onClick={() => setOpenSupportedFormats(true)} style={{ cursor: 'pointer', color: "blue" }}>
              See all supported formats
            </Typography> */}
            {/* <Button variant="outlined" onClick={() => setOpenSupportedFormats(true)}>
              Show Supported File Formats
            </Button> */}
          </div>


          <div className='layout-grid object-list-grid mx-[20px] '>
            {selectedSearchValue !== '' ? (
              bucketKey && selectedObjectByBucket.data ? (
                <CardItem
                  imageName={
                    selectedObjectByBucket.data.hasThumbnail
                      ? `data:image/jpeg;base64,${selectedObjectByBucket.data.thumbnail}`
                      : selectedObjectByBucket.data.objectType.name === 'iam'
                        ? `${IAM}`
                        : '' ||
                          selectedObjectByBucket.data.objectType.name === 'ipt'
                          ? IPT
                          : ''
                  }
                  title={selectedObjectByBucket.data.name}
                  text=''
                  buttonNames={[]}
                  buttonEvents={[
                    () => {
                      openObjectDetail(
                        selectedObjectByBucket.data as GetUploadedObjectResponseModel
                      );
                    },
                  ]}
                  onDelete={() => {
                    setSelectedDeleteValue(
                      selectedObjectByBucket.data?.name as string
                    );
                    setConfirmModal(true);
                  }}
                  onClickToCard={() => { }}
                />
              ) : (
                <>
                  {selectedObject.data !== null ? (
                    <>
                      <>
                        <CardItem
                          imageName={
                            selectedObject.data.hasThumbnail
                              ? `data:image/jpeg;base64,${selectedObject.data.thumbnail}`
                              : ''
                          }
                          title={selectedObject.data.name}
                          text=''
                          buttonNames={['X', 'Y']}
                          buttonEvents={[
                            () => {
                              openObjectDetail(
                                selectedObject.data as GetUploadedObjectResponseModel
                              );
                            },
                            async () => {
                              const service =
                                new GetUploadedObjectHtmlContentService(
                                  'objects',
                                  'content'
                                );
                              const data =
                                selectedObject.data as GetUploadedObjectResponseModel;
                              const response =
                                await service.GetByStringParamAsync(data.urn);

                              if (response.content != null) {
                                setHtmlContent(response.content);
                                setTextAreaModal(true);
                              }
                            },
                          ]}
                          onDelete={() => { }}
                          onClickToCard={async () => {
                            const service =
                              new GetUploadedObjectModelViewService(
                                'objects',
                                'show'
                              );
                            const data =
                              selectedObject.data as GetUploadedObjectResponseModel;
                            const response =
                              await service.GetByStringParamAsync(data.urn);

                            if (response.url !== null) {
                              window.open(response.url);
                            }
                          }}
                        />
                      </>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )
            ) : (
              <>
                {objectList && objectList.length > 0 ? (
                  <>
                    {objectList.map((object) => (
                      <div className='object-card-wrapper' key={object.name}>
                        <CardItem
                          imageName={
                            object.thumbnail !== ""
                              ? `data:image/jpeg;base64,${object.thumbnail}`
                              : object.name.split(".")[1] === 'iam'
                                ? IAM
                                : '' ||
                                  object.name.split(".")[1] === 'ipt'
                                  ? IPT
                                  : '' || object.name.split(".")[1] === 'zip'
                                    ? ZIP
                                    : ''
                          }
                          title={object.name}
                          text=''
                          buttonNames={['Detail', '</>']}
                          buttonEvents={[
                            () => openObjectDetail(object),
                            async () => {
                              setHtmlContent(
                                `<iframe frameborder='0' width='500px' height='500px' allowfullscreen mozallowfullscreen='true' webkitallowfullscreen='true' 
                                fullscreen; xr-spatial-tracking xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share
                                src='${baseUrl}objects/content/${object.urn}/view/${jsonLoginInfo.uid}'></iframe>`
                              );
                              setTextAreaModal(true);
                            }

                          ]}
                          onDelete={() => {
                            setSelectedDeleteValue(object.name);
                            setConfirmModal(true);
                          }}
                          onClickToCard={() => {
                            //openViewer(object.urn);
                            window.open(`${baseUrl}objects/content/${object.urn}/view/${jsonLoginInfo.uid}`)
                          }}
                        />
                      </div>
                    ))}
                  </>
                ) : (
                  <Container
                    className="modal show p-0 !w-full"
                    style={{
                      display: 'block',
                      position: 'initial',
                      textAlign: 'center',
                    }}
                  >
                    <Modal.Dialog>
                      <Modal.Header style={{ justifyContent: 'center' }}>
                        <Modal.Title>
                          <FontAwesomeIcon icon={faFlushed} style={{ fontSize: '2rem', color: '#ff6f61' }} />
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body style={{ fontSize: '1.1rem', lineHeight: '1.5', color: '#555' }}>
                        <p>
                          There are no objects available. Please click the upload button or use the cloud upload method to proceed.
                        </p>
                      </Modal.Body>
                    </Modal.Dialog>
                  </Container>

                )}
              </>
            )}
          </div>

          {uploadModal && (
            <MultipleUploadModal
              bucket={bucketKey}
              handleFile={handleFileEvent}
              handleFileDrop={handleFileDrop}
              handleCloseModal={handleCloseModal}
              setIsDragDrop={setUploadModal}
              setSelectedFiles={setSelectedFiles}
              selectedFiles={selectedFiles}
              openZipOption={openZipOption}
              setOpenZipOption={setOpenZipOption}
              setZipFile={setZipFile}
              zipFile={zipFile}
              processZipFile={processZipFile}
              extractZipStatus={extractZipFileStatus}
              setExtractZipStatus={setExtractZipFileStatus}
            />
          )}

          {
            openSupportedFormats && (
              <Dialog
                open={openSupportedFormats}
                onClose={() => setOpenSupportedFormats(false)}
                aria-labelledby="file-format-dialog-title"
                maxWidth="md"
                fullWidth
              >
                <DialogTitle id="file-format-dialog-title">Supported 2D and 3D file formats</DialogTitle>
                <DialogContent>
                  <Typography variant="body2" gutterBottom>
                    Veewer can display the following file formats:
                  </Typography>
                  <Grid container spacing={2}>
                    {[0, 1, 2].map((column) => (
                      <Grid item xs={4} key={column}>
                        <List dense>
                          {supportedFormats.slice(column * 20, (column + 1) * 20).map((format) => (
                            <ListItem key={format}>
                              <ListItemText primary={format} />
                            </ListItem>
                          ))}
                        </List>
                      </Grid>
                    ))}
                  </Grid>
                  {/* <Typography variant="caption" display="block" gutterBottom>
                    * Object-enabler viewing support only for AutoCAD® Architecture, AutoCAD® Plant 3D, and AutoCAD® Civil 3D.
                  </Typography>
                  <Typography variant="caption" display="block" gutterBottom>
                    ** Files from Revit® 2015 or later.
                  </Typography> */}
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setOpenSupportedFormats(false)}>Close</Button>
                </DialogActions>
              </Dialog>
            )
          }

          {filterModal && <></>}

          {confirmModal && (
            <ConfirmModal
              word="delete the file"
              ifYes={deleteSelectedObject.loading}
              onYes={() => {
                dispatch(deleteUploadedObject({
                  bucketKey,
                  objectKey: selectedDeleteValue
                })).then(() => {
                  window.location.reload();
                })
              }}
              onNo={async () => {
                setConfirmModal(false);
              }}
              show={confirmModal}
              onHide={setConfirmModal}
            />
          )}

          {textAreaModal && (
            <TextAreaModal
              show={textAreaModal}
              onHide={setTextAreaModal}
              incomingValue={htmlContent}
            />
          )}
        </div>
      )}
    </>
  ) : (
    <Unauthorized />
  );
};

export default ObjectList;


