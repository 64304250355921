import {NavDropdown, NavItem } from "react-bootstrap"
import { Link } from "react-router-dom"
import { OneDriveLogoutService } from "../../services/oneDrive/auth/logout.service"
import { LoginResponse } from "../../models/auth/login.model"
import { LogoutService } from "../../services/auth/logout.service"
import { useEffect } from "react"
import "../../styles/nav.css"
const OneDriveNav = () => {
    const clickedToOneDrive = sessionStorage.getItem("clickOneDrive")

    useEffect(() => {
        if (clickedToOneDrive !== null && clickedToOneDrive === "true") {
            sessionStorage.removeItem(clickedToOneDrive)
        }
    }, [clickedToOneDrive])
    return (
     
            <NavDropdown as={NavItem} title="OneDrive" className="dropend text-center" drop="end" style={{
                fontSize:"1.1rem"
            }}>
                
                <NavDropdown.Item><Link className="dropdownItem" to={"/oneDrive/models"}><b>Models</b></Link></NavDropdown.Item>
                {/* <NavDropdown.Item><Link className="dropdownItem" to={"/oneDrive/files"}>Translated Files</Link></NavDropdown.Item> */}
                <NavDropdown.Item className="dropdownItem" onClick={async()=>{
                    const logoutService=new OneDriveLogoutService()
                    const b=sessionStorage.getItem("b") as string
                    const res=await logoutService.DeleteByStringParamByAsync(b)
                    console.log(res)
                    if(res.isSuccess){
                        const microsoftInternalAuth=sessionStorage.getItem("login") as string
                        const jsonMicrosoftInternalAuth=JSON.parse(microsoftInternalAuth) as LoginResponse

                        if(microsoftInternalAuth && jsonMicrosoftInternalAuth &&jsonMicrosoftInternalAuth.microsoft_auth && jsonMicrosoftInternalAuth.isAuthenticated){
                                const secondaryLogoutService=new LogoutService()
                                const secondaryRes=await secondaryLogoutService.GetAsync()
                                if(secondaryRes.isSuccess){
                                    sessionStorage.removeItem('login');
                                    sessionStorage.removeItem('b')
                                }
                        }

                        sessionStorage.removeItem("microsoft-auth")
                         window.location.replace("/")

                    }
                }}><b>Logout</b></NavDropdown.Item>
        </NavDropdown>
    )
}


export default OneDriveNav